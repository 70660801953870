/* config.css */

:root {
    --baseColor: #53D4FA;
  }
  
  /* helpers/align.css */
  
  .align {
    display: grid;
   
            align-items: center;
    justify-items: center;
    place-items: center;
    width: 100%;
    height: 100%;
    background: #E5E5E5;;
    background: linear-gradient(180deg, #203C48 0%, #00B1E6 164.55%);
    overflow: hidden;
    object-fit: contain;
    padding-right: 5rem;
    
    position: fixed;

  }
  
  .grid {
    height: 100vh;
width: 40px;
padding-top: 5%;
border-radius: 30px;
padding-right: 42%;

  
  } 
  
  /* helpers/hidden.css */
  
  .hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  /* helpers/icon.css */
  
  :root {
    --iconFill: var(--baseColor);
  }
  
  .icons {
    display: none;
  }
  
  .icon {
    height: 1em;
    display: inline-block;
    fill: #606468;
    fill: var(--iconFill);
    width: 1em;
    vertical-align: middle;
  }
  
  /* layout/base.css */
  
  :root {
    --htmlFontSize: 100%;
  
    --bodyBackgroundColor: "#357edd";
    /* .blue {
      color: #357edd;
    }
    {
      color: #fff;
    }; */
    --bodyColor: var(--baseColor);
    --bodyFontFamily: "Open Sans";
    --bodyFontFamilyFallback: sans-serif;
    --bodyFontSize: 0.875rem;
    --bodyFontWeight: 400;
    --bodyLineHeight: 1.5;
  }
  
  * {
    -webkit-box-sizing: inherit;
            box-sizing: inherit;
  }
  
  html {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    font-size: 100%;
    font-size: var(--htmlFontSize);
  }
  
  body {
    background-color:beige;
    background-color: var(--bodyBackgroundColor);
    color: antiquewhite;
    color: var(--bodyColor);
    font-family: "Open Sans", sans-serif;
    font-family: var(--bodyFontFamily), var(--bodyFontFamilyFallback);
    font-size: 0.875rem;
    font-size: var(--bodyFontSize);
    font-weight: 400;
    font-weight: var(--bodyFontWeight);
    line-height: 1.5;
    line-height: var(--bodyLineHeight);
    margin: 0;
    min-height: 80vh;
  }
  
  /* modules/anchor.css */
  
  :root {
    --anchorColor: #eee;
  }
  
  a {
    color: #eee;
    color: var(--anchorColor);
    outline: 0;
    text-decoration: none;
  }
  
  a:focus,
  a:hover {
    text-decoration: underline;
  }
  
  /* modules/form.css */
  
  :root {
    --formGap: 0.875rem;
  }
  
  input {
    background-image: none;
    border: 0;
    color: inherit;
    font: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    -webkit-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }
  
  input[type="submit"] {
    cursor: pointer;
  }
  
  .form {
    display: grid;
    grid-gap: 0.875rem;
    gap: 60px;
    grid-gap: var(--formGap);
    gap: var(--formGap);
  }
  
  .form input[type="password"],
  .form input[type="text"],
  .form input[type="submit"] {
    width: 100%;
  }
  
  .form__field {
    display: "-webkit-box";
    display: "-ms-flexbox";
    display: flex;
    padding: 2%;
    padding-top: 2px;
    display: flex;
    flex-direction: row;
    
  }
  
  .form__input {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  
  /* modules/login.css */
  
  :root {
    --loginBorderRadus: 10px;
    
  
    --loginInputBackgroundColor: #FFFFFF;
    --loginInputHoverBackgroundColor: #FFFFFF;

    
  
    --loginLabelBackgroundColor: #FFFFFF;
    --background: #FFFFFF;
  
    --loginSubmitBackgroundColor: #53D4FA;
    --loginSubmitColor: #eee;
    --loginSubmitHoverBackgroundColor: #53D4FA;
  }
  
  .login {
    color: #eee;
    color: var(--loginColor);
  }
  
  .login label,
  .login input[type="text"],
  .login input[type="password"],
  .login input[type="submit"] {
    border-radius: 0.25rem;
    border-radius: var(--loginBorderRadus);
    padding: 1rem;
  }
  
  .login label {
    background-color: #363b41;
    background-color: var(--loginLabelBackgroundColor);
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  
  .login input[type="password"],
  .login input[type="text"] {
    background-color: #3b4148;
    background-color: var(--loginInputBackgroundColor);
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  
  .login input[type="password"]:focus,
  .login input[type="password"]:hover,
  .login input[type="text"]:focus,
  .login input[type="text"]:hover {
    background-color: #434a52;
    background-color: var(--loginInputHoverBackgroundColor);
  }
  
  .login input[type="submit"] {
    background-color: #00B1E6;;
    /* background-color: var(--loginSubmitBackgroundColor); */
    color: #eee;
    color: var(--loginSubmitColor);
    font-weight: 700;
    text-transform: uppercase;
  }
  
  .login input[type="submit"]:focus,
  .login input[type="submit"]:hover {
    background-color: rgb(54, 59, 65);
    background-color: var(--loginSubmitHoverBackgroundColor);
  }
  
  /* modules/text.css */
  
  p {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .text--center {
    text-align: center;
  }
  .toast{
    background: "red !important"
  }
  .logoName{
    text-align: justify;
    color:rgb(207, 89, 26);
    font-size: 30px;
align-items: center;
   letter-spacing: 10px;
   /* vertical-align: baseline; */
  }

  /* .form__sumbit{
    pointer-events: none; 
     display: -webkit-box;
    display:-webkit-inline-box;
    display: flex;
    height:5rem;
    width:20rem;
    margin-left: 10rem;
    background-color: unset;
    
  } */

  .login_button{
    display:flex;
    margin-left: 13rem;
   
    height:3rem;
  }
  
  .forgotPassword{
    display: flex;
    padding-left: 17rem;
    padding-bottom: 160px;
font-family: 'Poppins';
font-style: normal;
font-weight: 550;
font-size: 14px;
line-height: 5px;
color: rgba(0, 177, 230, 1);
margin-top: 0.5rem;
margin-bottom: 4rem;
    
  }

  .login__name{
  display: flex;
  text-align: center;
  color: #000000;
  padding-left: 44%;
  font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 45px;
  }

  .paragraph{

    width: 131px;
height: 27px;
left: 378px;
top: 473px;

font-family: 'Poppins';
font-style: normal;
font-weight: 550;
font-size: 14px;
line-height: 10px;
color: #000000;

  }