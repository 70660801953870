*{
    margin: 0px;
    padding: 0px;
}
.edituserbox{
    padding: 20px;
    background-color: white;
}
.formfield{
    margin:20px auto;
}