.css-67bhig-MuiTableCell-root {
  padding: 0px 10px !important;
}
.css-s97c5p-MuiTableCell-root.MuiTableCell-head {
  padding: 20px 10px !important;
}
.table_box {
  margin: 0px;
}

.css-o46k6g-MuiTableCell-root{
  text-align: left !important;
  padding-left: 10px !important;
}