.table_heading {
  background-color: white;
}

.taskButton {
  background: #00b1e6;
  padding: 0.2rem 1.8rem;
  border-radius: 5px;
}

/* .rowTable {
  background-color: white !important;
  color: black !important;
} */

.rowTable.css-1q1u3t4-MuiTableRow-root {
  background-color: white !important;
  color: black !important;
}

.MuiPaper-root
  MuiPaper-elevation
  MuiPaper-rounded
  MuiPaper-elevation24
  MuiDialog-paper
  MuiDialog-paperScrollPaper
  MuiDialog-paperWidthSm
  css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 60rem !important;
}

/* .css-1bhpkcd-MuiTableCell-root {
  text-align: center !important;
} */
