  
  .updateRight{
    box-shadow: 3px 6px 17px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 3px 6px 17px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 3px 6px 17px 0px rgba(0,0,0,0.75);
    display: flex;
    width: 70%;
    margin:0px auto;
    justify-content: space-around;
    padding:20px 20px;
  }
  .textfield{
  margin: 20px 0px;
    
  }
  input{
    border: none;
    border-bottom: 2px solid rgb(131, 129, 129);;
    margin-top: 15px;
    font-size: 10px;
  }
  
  .formtableRow{
      display: flex;
      flex-direction: column;
      margin: 20px 0px;
    }
    .formLabel{
        font-weight: bold;
       color: #5550bd;
    }
    .Img{
     
      width:200px;
      border-radius: 10px;
   
  }
  .profileImage{
      
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 30px ;
  }
  .updateBtn{
      width: 60%;
margin-bottom: 40px;
background-color: darkblue;
color: white;
border: none;
border-radius: 10px;
padding: 10px;
margin: 20px auto;
cursor: pointer;
  }
  .uploadImg{
  
  }