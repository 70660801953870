.datefiel{
    display: flex;
    margin: 25px 30px 40px 0px;
}
.MuiDataGrid-columnHeader{
    /* background-color: rgb(32, 60, 72); */
    background: linear-gradient(180deg, #203C48 0%, #00B1E6 164.55%);
color:white
}

.css-30pzqk-MuiDataGrid-root .MuiDataGrid-cell {
    white-space:pre-wrap !important;
}
.MuiDataGrid-root .MuiDataGrid-cell{
     
    /* white-space: 'normal' !important;  */
    /* word-wrap: 'break-word' !important; */
    line-height:20px     !important;
}
    