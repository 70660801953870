.reset__pass {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  font-size: 28px;
  color: #000000;
  padding-left: 10rem;
  padding-top: 1rem;
}

.text {
  padding-left: 2px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  padding-top: 0.5rem;
  padding-right: 6px;

  color: #555555;
}

.address {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
}

.container_main {
 
  display: flex;

  align-items: center;
  justify-items: center;
  place-items: center;
  width: 100%;
  height: 100%;
  background: #e5e5e5;
  background: linear-gradient(180deg, #203c48 0%, #00b1e6 164.55%);
  overflow: hidden;
  object-fit: contain;
  padding-bottom: 4rem;

  position: fixed;
}

.card_css {
  padding-top: 4rem;
  padding-left: 23rem;
}

.input_field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 2%;
  padding-top: 2px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  /* position: absolute; */
  width: 500px;
  height: 40px;
  background: #ffffff;
  border: 2px solid #c5c4c4;
  border-radius: 2px;
}

.button {
  display: flex;
  justify-content: center;
  margin-right: 3rem;
  padding-top: 1rem;
}

.goBackTxt {
  display: flex;
  justify-content: center;
  margin-right: 3rem;
  padding-top: 14px;
}
