.css-67bhig-MuiTableCell-root.MuiTableCell-head {
  background-color: rgb(20, 37, 44) !important;
  color: white;
}
.css-s97c5p-MuiTableCell-root.MuiTableCell-head {
  background-color: rgb(20, 37, 44) !important;
  color: white;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  min-width: 60rem !important;
}
.cancelButton {
  background: rgba(196, 196, 196, 0.5) !important;
  padding: 5px 0px !important;
  margin: 20px 0px !important;
  color: black !important;
  text-align: center !important;
  border-radius: 5px !important;
  width: 9rem;
}
.dateBox{
  padding: 5px 20px;
    width: 177px;
    background-color: rgb(240, 240, 240);
    border: 1px solid rgb(221, 221, 221);
    border-radius: 5px;
    margin-bottom: 0.3rem;
}
