input[type="password"]::-ms-reveal {
  display: none;
}
.btn {
  margin-top: 1.75em;
}
.input-group {
  position: relative;
  min-width: 60rem;
}
.toggle {
  background: none;
  border: none;
  color: #337ab7;

  font-weight: 600;

  right: 0.75em;
  top: 2.25em;
  z-index: 9;
}

.fa {
  font-size: 2rem;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  min-width: 60rem !important;
  min-height: 40rem !important;
  margin-left: 18rem !important;
}

.txtlabel {
  font-weight: 1000 !important;
  font: Montserrat !important;
}

.txtfield {
  margin-bottom: 2rem !important;
}

.button_s {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  
  /* padding: 0.5rem 5rem 0.5rem 1rem; */
}

.cancel_button {
  background: rgba(196, 196, 196, 0.5) !important;
  padding: 5px 0px !important;
  margin: 20px 0px !important;
  color: black !important;
  text-align: center !important;
  border-radius: 5px !important;
  width: 9rem;
}
