* {
  margin: 0px;
  padding: 0px;
}

.dashboard_box {
  width: 96%;
  margin: 0px auto;
}
.dashboard_wlcCard {
  background-color:#53D4FA;


  margin: 20px auto;
  border-radius: 5px;
}
.wlcCard_title {
  font-size: 25px;
   height: 70px;
  font-weight: bold;
  color: whitesmoke;
  padding: 15px 20px;
  margin: 0px;
}
.dashboard_useractivity {
  display: flex;
  justify-content: space-between;
}
.useractive_box {
  background: rgb(219, 219, 221);
  width: 160px;
  margin: 30px 15px;
  height: 94px;
  border-radius: 10px;
}
.useractive_innerbox {
  padding-top: 6px;

  margin: auto;
  text-align: center;
}
.box_title {
  font-size: 14px;
  color: #2f4439;
  font-weight: bold;
}
.box_number {
  font-size: 50px;
}
.dashboard_table {
  border: 1px solid rgb(219, 219, 221);
  background: rgb(255, 255, 255);
}
.table_heading {
  font-size: 20px;

  border-bottom: 1px solid rgb(219, 219, 221);
  padding: 10px 25px;
}

.table_box {
  margin: 25px 20px;
}
