/* .formbox {
  height: 80%;
  width: 70%;
} */
.txtfield {
  padding: 5px 20px;
  width: 400px;
  background-color: rgb(240, 240, 240);
  border: 1px solid rgb(221, 221, 221);
  border-radius: 5px;
  margin-bottom: 1rem;
}
.selectfield {
  padding: 5px 20px;
  width: 250px;
  margin: 0px;
  background-color: rgb(240, 240, 240);
  border: 1px solid rgb(221, 221, 221);
  border-radius: 5px;
}
.datefield {
  padding: 5px 20px;
  width: 350px;
  background-color: rgb(240, 240, 240);
  border: 1px solid rgb(221, 221, 221);
  border-radius: 5px;
  margin-bottom: 0.3rem;
}

.categoryField {
  padding: 5px 20px;
  margin-bottom: 2rem;
  width: 400px;
  background-color: rgb(240, 240, 240);
  border: 1px solid rgb(221, 221, 221);
  border-radius: 5px;
}

textarea,
select {
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  font-size: 16px;
  height: auto;
  margin: 0;
  outline: 0;
  padding: 15px;
  width: 100%;
  background-color: rgb(240, 240, 240);
  color: black;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
}

.field {
  margin: 0px auto;

  border: none;
  width: 90%;
}

legend {
  font-size: 1.4em;
  margin-bottom: 1rem;
}

.txtlabel {
  font-size: 13px;
  color: black;
  display: block;
  margin: 20px 10px 2px 0px;
  width: 400px;
}

.txtlabel.light {
  font-weight: 300;
  display: inline;
}
.textareafield {
  margin: 30px 0px 0px 0px;
}
input[type="submit"] {
  float: right !important;
  padding: 5px 20px !important;
  color: white !important;
  border-radius: 5px !important;
  background: #00b1e6;
  margin: 20px 0px !important;
}

.css-1m2nwe6-MuiModal-root-MuiDialog-root .MuiPaper-root {
  height: 600px;
}

.cancelButton {
  background: rgba(196, 196, 196, 0.5) !important;
  padding: 5px 0px !important;
  margin: 20px 0px !important;
  color: black !important;
  text-align: center !important;
  border-radius: 5px !important;
  width: 9rem;
  border: none;
}
