* {
  margin: 0px;
  padding: 0px;
}

.passwordbox {
  width: 60%;
  height: 75vh;
  margin: 20px auto;
  background-color: white;
}
.boxtitle {
  padding: 30px 0px;
  text-align: center;
}
.passwordinputs {
  border: 2px solid rgb(212, 210, 210);
  background-color: rgb(240, 240, 240);
  margin: 3px 0px;
  width: 350px;
  height: 30px;
  border-radius: 5px;
}
.inputsfields {
  width: 70%;
  margin: 0px auto;
}
.singleinput {
  margin: 20px 0px;
}
.futerbutton {
  margin: 20px 0px;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
