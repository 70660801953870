.categoryButton {
  background: #00b1e6;
  padding: 0.3rem 1.2rem;
  border-radius: 5px;
}

.table_heading {
  color: "black";
  font-weight: 600;
}
