* {
  margin: 0px;
  padding: 0px;
  font-family: "Montserrat", sans-serif;
}
.custom_model .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 65rem !important;
  min-height: 40rem !important;
  overflow-x: hidden !important;
}
