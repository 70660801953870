.table_heading {
  display: flex;
  justify-content: space-between;
  background-color: white;
  color: white;
}

.table_heading_name {
  color: black;
}

.empty-state__message {
  color: #38a169;
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 0.85rem;
  width: 50%;
  margin: 100px auto;
}

.editButton {
  cursor: pointer;
}
.deleteButton {
  cursor: pointer;
}

.button_AddProject {
  background: #00b1e6;
  border-radius: 5px;
  width: 8rem;
  font-weight: 800;
  display: flex;
  justify-content: center;
}

.dashboard_table {
  margin-top: 2rem;
}
