.sidebar_box {
  border: 2px solid black;
  width: 100%;
  height: 100vh;
  flex: 1;
}
.drower_header {
  background: linear-gradient(180deg, #203c48 0%, #00b1e6 164.55%);
}
.drower_list {
  background: linear-gradient(180deg, #203c48 0%, #00b1e6 164.55%);
  margin: 40px 0px;
  height: 100vh;
}
.liststyle {
  background: linear-gradient(180deg, #203c48 0%, #00b1e6 164.55%);
}
.list_text {
  color: honeydew;
}
.list_items {
  width: 100%;
  margin: 0px auto;
  margin: 0px 10px;
}
.top_heading {
  display: flex;
  align-items: center;
  padding-left: 35px;
}
.main_box {
  background: rgb(241, 243, 246);
  background: linear-gradient(180deg, #203c48 0%, #00b1e6 164.55%);
  height: 100vh;
}
.sideBarProfile {
  width: 84%;
  display: flex;
  align-items: center;
  padding-right: 30px;
}
.userName {
  padding: 10px 0px;
}
.headerLogout {
  display: flex;
  justify-content: space-between;
}
.headerName {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.userLogout {
  display: flex;
}

.etelligensLogo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list_bar {
  color: white;
  font-size: 1.2em;
  cursor: pointer;
  display: block;
}

.list_bar::after {
  float: right;
  right: 10%;
}

.dropDownSlide {
  clear: both;
  width: 100%;
  height: 0px;
  padding-left: 4rem;
  overflow: hidden;
  transition: height 0.4s ease;
}

.dropDownSlide .slider_Link {
  padding: 10px 0px;
  list-style: none;
}

#touch {
  position: absolute;
  opacity: 0;
  height: 0px;
}

#touch:checked + .dropDownSlide {
  height: 17%;
}

