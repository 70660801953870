.MainContainer{
    background: #FFFFFF;
}

.Icon{
    display: flex;
    align-items: center;
    margin-left: auto;
    
}

.Imageicon{

    display: flex;
  justify-content: space-between;
  align-items: center;
   margin-right: auto;
   padding-left: 23rem;
}

.wlcCard_title{
    color: #3C4C6B;
    margin-right: 13rem;
}